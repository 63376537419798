<template>
  <v-col cols="12">
    <v-toolbar v-if="obModel && obModel.name" flat>
      <v-toolbar-title>
        {{ obModel.name }}
        <span class="text-caption grey--text"
          >( {{ obModel.old_company_id }} )</span
        >
      </v-toolbar-title>

      <v-spacer />

      <v-progress-circular
        :color="progressColor"
        :value="progress"
        class="mr-4"
        width="2"
        size="40"
      >
        <span class="text-caption">{{ progress }}%</span>
      </v-progress-circular>

      <v-btn
        v-if="!!obModel && !!obModel.id"
        color="primary"
        depressed
        rounded
        small
        :disabled="progress >= 100"
        :loading="loading"
        @click="dispatch"
      >
        <icon-play class="mr-2" outlined />
        {{ $t("start.migration") }}
      </v-btn>
    </v-toolbar>

    <v-divider />

    <v-list v-if="modules.length">
      <template v-for="obModule in modules">
        <module-list-item
          :key="`monitor.${obModel.id}.module.${obModule.id}`"
          :item="obModule"
        />
      </template>
    </v-list>
  </v-col>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import type {
  MigrationModule,
  MigrationModuleCollection,
} from "@planetadeleste/vue-mc-gw";
import { MigrationMonitor } from "@planetadeleste/vue-mc-gw";
import ModuleListItem from "@/modules/migrations/components/ModuleListItem.vue";
import type { Channel } from "pusher-js";
import { usePusher } from "@/services/pusher";
import type {
  MonitorLoadingMigrationChannel,
  MonitorProgressMigrationChannel,
} from "@/types/broadcast";
import { number } from "mathjs";

@Component({
  components: { ModuleListItem },
})
export default class MigrationsView extends Vue {
  obModel: MigrationMonitor | null = null;
  obModuleCollection: MigrationModuleCollection | null = null;
  obMonitorProgressChannel: Channel | null = null;
  obMonitorLoadingChannel: Channel | null = null;
  obPusher = usePusher();

  loading = false;

  get modules(): MigrationModule[] {
    return this.obModuleCollection?.getModels() ?? [];
  }

  get progress(): number {
    return this.obModel ? this.obModel.get("progress", 0) : 0;
  }

  get progressColor(): "green" | "primary" {
    return this.progress > 70 ? "green" : "primary";
  }

  async dispatch(): Promise<void> {
    if (!this.obModel) {
      return;
    }

    this.loading = true;
    await this.obModel.dispatch();
  }

  onProgress(obData: MonitorProgressMigrationChannel): void {
    if (!this.obModel || obData.monitor_id !== this.obModel.id) {
      return;
    }

    this.obModel.set("progress", obData.progress);
  }

  onLoading(obData: MonitorLoadingMigrationChannel): void {
    if (!this.obModel || obData.monitor_id !== this.obModel.id) {
      return;
    }

    this.loading = obData.loading;
  }

  created(): void {
    this.obMonitorProgressChannel = this.obPusher.channel(
      "migration",
      "monitor_progress",
      this.onProgress
    );
    this.obMonitorLoadingChannel = this.obPusher.channel(
      "migration",
      "monitor_loading",
      this.onLoading
    );
  }

  beforeDestroy() {
    if (this.obMonitorProgressChannel) {
      this.obMonitorProgressChannel.disconnect();
    }
    if (this.obMonitorLoadingChannel) {
      this.obMonitorLoadingChannel.disconnect();
    }
  }

  async mounted() {
    if (!this.$route.params.id) {
      return;
    }

    this.obModel = new MigrationMonitor({ id: number(this.$route.params.id) });
    await this.obModel.fetch();

    this.obModuleCollection = await this.obModel.modules();
  }
}
</script>
